<!-- 挂号支付结果 -->
<template>
  <div class="payResult">
    <div v-if="type === 'success'">
      <img src="../../assets/img/paysuccess.png" alt="" class="logo">
      订单支付成功，5秒后跳转到问诊签到列表，进行签到候诊。
    </div>
    <div v-else>
      <img src="../../assets/img/payerror.png" alt="" class="logo">
      <div class="tips">
        抱歉, 您的订单支付失败
      </div>
    </div>
    <div v-if="type === 'success'" class="btn-top" @click="goConsultation">
      <span>
        <van-button
          class="btn btn-primary"
          type="primary"
          native-type="button"
          @click="getroute"
        >
          {{ text }}
        </van-button>
      </span>
    </div>
    <div class="btn btn-primary" @click="goback">
      返回首页
    </div>
  </div>
</template>

<script>
import { userModel } from "@/api/user"
import wx from "weixin-webview-jssdk"
export default {
  name: "PayResult",
  components: {},
  data() {
    return {
      time: 4000,
      type: "success",
      payData: {},
      text: "问诊签到(5秒后自动跳转)",
      //
      href: ""
    }
  },
  computed: {},
  mounted() {
    this.type = this.$route.query.type
    this.href = window.location.href
    if (this.type === "success") {
      this.countdown()
    }
    // this.getPayData()
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  methods: {
    onPaySuccess() {
      userModel.bindAddress(this.preOrderId, this.addressId).then(() => {})
    },
    countdown() {
      let time = 5
      this.timer = setInterval(() => {
        if (time === 0) {
          clearTimeout(this.timer)
          this.getroute()
        } else {
          this.text = `问诊签到(${time}秒后自动跳转)`
          time--
        }
      }, 1000)
    },
    getroute() {
      if (this.IsMiniProgramEnv()) {
        console.log("在小程序内部，跳转至我的问诊")
        wx.miniProgram.navigateTo({ url: "/pages/consultation/index" })
        return
      }
      this.$router.push({
        path: "/consultation"
      })
    },
    goback() {
      if (this.IsMiniProgramEnv()) {
        wx.miniProgram.navigateTo({ url: "/pages/index/index" })
        return
      }
      this.$router.push({
        path: "/"
      })
    },
    goConsultation() {
      if (this.IsMiniProgramEnv()) {
        wx.miniProgram.navigateTo({ url: "/pages/consultation/index" })
        return
      }
      this.$router.push({
        path: "/consultation"
      })
    },
    getPayData() {
      // todo
    },
    // 判断是否是小程序环境
    IsMiniProgramEnv() {
      // 通过navigator.userAgent 判断
      let isMini = false
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            isMini = true
          } else {
            isMini = false
          }
        })
      } else {
        isMini = false
      }
      return isMini
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/styles/variable.less";
.payResult {
  // padding: 32px;
  width: 100%;
  text-align: center;
  img {
    // margin-top: 52px;
    // margin-bottom: 100px;
    display: block;
    margin: 100px auto 52px auto;
    width: 271px;
    height: 264px;
  }
  .tips {
    width: 408px;
    height: 66px;
    margin: 0 auto;
    font-size: 24px;
    font-weight: 400;
    color: #999999;
    line-height: 33px;
  }
  // .btn {
  //   margin-top: 70px;
  //   width: 200px;
  // }
  .btn {
    width: 450px;
    height: 68px;
    line-height: 68px;
    font-size: 30px;
    text-align: center;
    border-radius: 12px;
    border-color: #1c9cf6;
    margin: 0 auto;
    margin-top: 30px;
  }
  .btn-default {
    color: #666;
    background: #ddd;
  }
  .btn-top {
    margin-top: 100px;
  }
  .btn-primary {
    color: #fff;
    background: #1c9cf6;
  }
}
</style>
